import React from "react";
import "./SaleModal.css";

import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

function ModalFunc(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="sale-modal"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header className="saleModalHeader" closeButton></Modal.Header>
      <Modal.Body>
        <div className="saleModal-content">
          <h4>Let’s Get Started</h4>
          <p>
            Fill out the form below and we will be in touch to discuss your
            requirement
          </p>

          <form>
            <input type="text" placeholder="Full Name*" />
            <input type="text" placeholder="Business Email*" />
            <input type="text" placeholder="Phone*" />
            <input type="text" placeholder="Company Name*" />
            <input type="text" placeholder="Services You Are Looking For?" />

            <button>Submit</button>
          </form>
        </div>
      </Modal.Body>
    </Modal>
  );
}

function SaleModal() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <Link to={"#"} className="orange-btn" onClick={() => setModalShow(true)}>
        Talk to Sales
      </Link>

      <ModalFunc show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default SaleModal;
