import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./EcommerceBanner.css";

import FreeConsultModal from "../FreeConsultModal";

const EcommerceBanner = ({ scrollToServicesSec, content }) => {
  return (
    <section className="va-assistance-sec ecom-assistance-sec">
      <Container>
        <Row className="va-assistance-row">
          <Col lg={6}>
            <div className="va-assistance-content">
              <div className="va-small-heading">
                <div className="inner-dot"></div>
                <p className="inner-dot-para">{content.orangeTitle}</p>
              </div>

              <h1>
                {content.heading1}
                <span> {content.heading2}</span>
              </h1>

              <p>{content.para}</p>

              <div className="va-banner-btn">
                <FreeConsultModal />
                <Link
                  to={"#"}
                  onClick={scrollToServicesSec}
                  className="orange-outline-btn"
                >
                  Our Services
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="va-assistance-img ecom-banner-icon-before">
              <img src={content.image} alt="EcommerceBannerImg" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default EcommerceBanner;
