import React from "react";
import "./AboutUs.css";

import AboutBanner from "../../components/AboutBanner";

import TrustedComp from "../../components/TrustedComp/TrustedComp";
import EcommerceSupport from "../../components/EcommerceSupport";
import OurLocation from "../../components/OurLocation";
import Testimonials from "../../components/Testimonials";
import FreeTrial from "../../components/FreeTrial";
import { Helmet } from "react-helmet";

import TestiImgThree from "../../assets/images/testiImg3.png";

const AboutUs = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ConvoBird | About Us</title>
      </Helmet>
      <AboutBanner />

      <TrustedComp />

      <EcommerceSupport learmMore={"Learn More"} />

      <OurLocation />

      <Testimonials testiImgThree={TestiImgThree} />
      <FreeTrial />
    </>
  );
};

export default AboutUs;
