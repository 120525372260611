import React from "react";
import "./TeamSec.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import TeamSecImg from "../../assets/images/team-sec-img.png";

const TeamSec = () => {
  const location = useLocation();
  const currentURL = location.pathname;
  return (
    <section
      className={currentURL === "/pricing" ? "pricing-team-sec" : "team-sec"}
    >
      <Container>
        <Row className="teamSec-Row">
          <Col lg={6}>
            <div className="team-sec-content">
              <div className="home-solution">
                <div className="dot"></div>
                <p>Team</p>
              </div>
              <h2>
                Smart Experts <span>24/7</span>
              </h2>
              <p>
                Experience unmatched support with our smart experts, available
                around the clock at ConvoBird. We understand the importance of
                instant assistance and expertise.
              </p>
              <p>
                Smart Assistance: Our knowledgeable experts are ready to assist
                you with any queries, ensuring quick and intelligent solutions.
              </p>
              <p>
                24/7 Availability: Day or night, we're here to provide support
                whenever you need it, making your experience seamless.
              </p>
              <p>
                Real-time Solutions: Get instant answers to your questions
                through our live chat feature.
              </p>
              <p>
                Expert Guidance: Benefit from the expertise of our smart
                professionals who are dedicated to enhancing your chat
                experience.
              </p>

              <div className="teamSec-btn">
                <Link to={"/contact-us"}>Contact Us</Link>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="teamSec-img">
              <img src={TeamSecImg} alt="team sec img" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TeamSec;
