import React from "react";
import "./OurLocation.css";
import { Col, Container, Row } from "react-bootstrap";

import UtahImg from "../../assets/images/utha-img.png";
import NewYorkImg from "../../assets/images/new-york-img.png";
import DubaiImg from "../../assets/images/dubai-img.png";

const OurLocation = ({ url }) => {
  const sectionStyle = url === "/contact-us" ? { margin: 0 } : {};
  return (
    <section className="OurLocation-sec" style={sectionStyle}>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="OurLocation-content">
              <div className="va-small-heading">
                <div className="inner-dot"></div>
                <p className="inner-dot-para">Location</p>
              </div>

              <h2>Our Offices</h2>
            </div>
          </Col>
        </Row>

        <Row className="Ourlocation-row2">
          <Col lg={4} md={6} sm={6} xs={12}>
            <div className="OurLocation-cards-div">
              <img src={UtahImg} className="same-hover1" alt="UthaImg" />

              <div className="OurLocation-cards-content">
                <h3>Utah</h3>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6} sm={6} xs={12}>
            <div className="OurLocation-cards-div">
              <img src={NewYorkImg} alt="NewYorkImg" />

              <div className="OurLocation-cards-content OurLocation-cards-content1">
                <h3>New York</h3>
              </div>
            </div>
          </Col>
          <Col lg={4} md={6} sm={6} xs={12}>
            <div className="OurLocation-cards-div OurLocation-cardsBefore">
              <img src={DubaiImg} className="same-hover" alt="DubaiImg" />

              <div className="OurLocation-cards-content OurLocation-cards-content2">
                <h3>Dubai</h3>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default OurLocation;
