import React, { useRef } from "react";
import "./CreativeDesignVA.css";
import { Helmet } from "react-helmet";

import EcommerceBanner from "../../components/EcommerceBanner";

import CreativeDesignBanner from "../../assets/images/creative-design-banner.png";
import CoveredPlatforms from "../../components/CoveredPlatforms";
import EcommerceBenefits from "../../components/EcommerceBenefits";
import FreeConsultation from "../../components/FreeConsultation";
import EcomServices from "../../components/EcomServices";
import EasiestHire from "../../components/EasiestHire";
import SelectHours from "../../components/SelectHours";
import Testimonials from "../../components/Testimonials";
import StepsComp from "../../components/StepsComp";

import IncreasedImage from "../../assets/images/increased-image.png";
import EnhancedImg from "../../assets/images/enhanced-img.png";
import ImprovedImg from "../../assets/images/improved-img.png";
import AdaptiveImg from "../../assets/images/adaptive-img.png";
import BirdIcon from "../../assets/images/bird-icon.png";
import testiImgFive from "../../assets/images/testiImg5.png";

const CreativeDesignVA = () => {
  const servicesRef = useRef(null);
  const stepFormRef = useRef(null);

  const scrollToServicesSec = () => {
    servicesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToStepFormSec = () => {
    stepFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const BannerContent = {
    image: CreativeDesignBanner,
    orangeTitle: "Ecommerce",
    heading1: "Creative Design Virtual",
    heading2: "Assistance",
    para: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam",
  };

  const ecommerceBenefitsContent = {
    orangetitle: "Benefits",
    heading1: "Benefits of Getting A Creative",
    heading2: "Design",
    heading3: "Virtual Assistant",
    ecomBenefitsContentBox: [
      {
        title: "Increased Efficiency and Productivity",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam",
        imgSrc: IncreasedImage,
      },
      {
        title: "Enhanced Creativity and Inspiration",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam",
        imgSrc: EnhancedImg,
      },
      {
        title: "Improved Organization and Collaboration",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam",
        imgSrc: ImprovedImg,
      },
      {
        title: "Adaptive Learning and Personalization",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam",
        imgSrc: AdaptiveImg,
      },
    ],
  };

  const ecomServicesContent = {
    orangeTitle: "Services",
    heading1: "What A Creative Design",
    heading2: "Virtual Assistance",
    heading3: "Can Do",
    services: [
      {
        title: "Idea Generation",
        subtitle: "and Inspiration",
        icon: BirdIcon,
        listItems: [
          "Executive assistant",
          "Data entry",
          "Manage files and documents",
          "Update product lists and prices",
          "Manage stock levels and place orders",
          "Accounting",
          "Bookkeeping",
          "Tax preparation",
          "Payroll",
          "Billing",
        ],
      },
      {
        title: "Mockup and",
        subtitle: "Prototyping",
        icon: BirdIcon,
        listItems: [
          "Shopify Developer",
          "WordPress Developer",
          "Amazon store setup",
          "Etsy store setup",
          "Managing BigCommerce and other platforms",
          "Walmart store setup",
          "IT support",
          "Identifying and updating broken links",
          "Back-end SEO",
          "Monitoring and improving page load speed",
        ],
      },
      {
        title: "Resource",
        subtitle: "Management",
        icon: BirdIcon,
        listItems: [
          "Placing and updating orders",
          "Order tracking",
          "Handling product returns and exchanges",
          "Processing orders and refunds",
          "Managing returns",
          "Product recommendations",
          "Upselling and cross-selling",
          "Handling customer complaints",
          "Responding to product reviews",
          "24/7 chat support",
        ],
      },
      {
        title: "Workflow",
        subtitle: "Optimization",
        icon: BirdIcon,
        listItems: [
          "Writing and editing product descriptions",
          "Web Development",
          "Social media management",
          "Writing ad copy",
          "Creating images for ads and social posts",
          "Video editing",
          "Customer research",
          "Creating blog content",
          "Track social media performance",
          "Keyword research",
        ],
      },
    ],
  };

  const stepCompContent = {
    title: "Hire Design VA",
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ConvoBird | Creative Design Virtual Assistance</title>
      </Helmet>

      <EcommerceBanner
        content={BannerContent}
        scrollToServicesSec={scrollToServicesSec}
      />
      <CoveredPlatforms />

      <EcommerceBenefits content={ecommerceBenefitsContent} />

      <FreeConsultation scrollToStepFormSec={scrollToStepFormSec} />

      <EcomServices
        pageType="creative"
        content={ecomServicesContent}
        ref={servicesRef}
      />

      <EasiestHire />

      <SelectHours />

      <Testimonials testiImgFive={testiImgFive} />

      <StepsComp content={stepCompContent} ref={stepFormRef} />
    </>
  );
};

export default CreativeDesignVA;
