import React from "react";
import "./ContactUs.css";

import ContactBanner from "../../components/ContactBanner";
import OurLocation from "../../components/OurLocation";
import FreeTrial from "../../components/FreeTrial";
import SaleModal from "../../components/SaleModal";

import { Col, Container, Row } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import FreeConsultModal from "../../components/FreeConsultModal";

const ContactUs = () => {
  const location = useLocation();
  const currentURL = location.pathname;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ConvoBird | Contact Us</title>
      </Helmet>
      <ContactBanner />

      <section className="lead-industry">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="lead-industry-content">
                <h2>
                  Supercharge Your Business with <span>ConvoBird</span>
                </h2>

                <Row>
                  <Col lg={12}>
                    <p>
                      Ready to elevate your online presence? Connect with our
                      Sales Team for an in-depth discussion on how ConvoBird can
                      transform your business.
                    </p>
                  </Col>

                  <Col lg={6} md={6}>
                    <div className="supercharge-para supercharge-para_border">
                      <h3>Why ConvoBird?</h3>
                      <p>
                        Save time, boost sales, and foster customer loyalty with
                        our cutting-edge e-commerce Virtual Assistants. They're
                        designed to seamlessly build, manage, and maintain your
                        store, giving you the competitive edge in the digital
                        landscape.
                      </p>
                    </div>

                    <div className="lead-industry-btn-box lead-industry-btn-box2">
                      <SaleModal />
                    </div>
                  </Col>

                  <Col lg={6} md={6}>
                    <div className="supercharge-para">
                      <h3>Seamless Setup with Your New Virtual Assistant</h3>
                      <p className="supercharge-para">
                        Getting started with your new Virtual Assistant at
                        ConvoBird is a breeze! We prioritize creating happy and
                        enduring client relationships. Our journey together
                        begins with a personalized consultation, setting the
                        stage for a seamless and effective collaboration.
                      </p>
                    </div>

                    <div className="lead-industry-btn-box">
                      <FreeConsultModal hireAVA="Hire A VA" />
                    </div>
                  </Col>

                  <Col lg={12}>
                    <div className="contact-content-para">
                      <p>
                        Connect with Us: Let our Sales Team guide you through
                        the incredible benefits of integrating ChatApp into your
                        business strategy. Schedule a consultation today!
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <OurLocation url={currentURL} />
      <FreeTrial url={currentURL} />
    </>
  );
};

export default ContactUs;
