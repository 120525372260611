import React from "react";
import "./Testimonials.css";
import { Col, Container, Row } from "react-bootstrap";

import TestiImg1 from "../../assets/images/testiImg1.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";

const Testimonials = ({
  testiImgThree,
  testiImgTwo,
  testiImgFour,
  testiImgFive,
  testiImgSix,
}) => {
  const location = useLocation();
  const locationURL = location.pathname;

  const testiData = [
    {
      id: 1,
      text: `Game-Changer for Business Communication!" ⭐️⭐️⭐️⭐️⭐️ "As a business owner, ConvoBird has transformed the way we communicate internally. The intuitive interface, coupled with powerful features, has made collaboration seamless. Highly recommend!`,
      title: "Jaroslav Brabec",
    },

    {
      id: 2,
      text: `Exceptional Customer Support!" ⭐️⭐️⭐️⭐️⭐️ "I encountered a minor issue, and the support team at ConvoBird was quick to respond and resolve it. Their 24/7 support truly sets them apart. Great service!`,
      title: "James Parker",
    },

    {
      id: 3,
      text: `Effortless and Efficient!" ⭐️⭐️⭐️⭐️⭐️ ConvoBird is my go-to for personal and professional communication. It's easy to use, and the chat features are top-notch. It has streamlined my conversations and boosted my productivity.`,
      title: "Lyla King",
    },

    {
      id: 4,
      text: `Secure and Reliable!" ⭐️⭐️⭐️⭐️⭐️ "I appreciate the emphasis on security. ConvoBird provides a secure platform for confidential discussions. I feel confident using it for both casual chats and important business conversations.`,
      title: "Samantha Gray",
    },
  ];

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nav: true,
    autoplay: true,
  };

  return (
    <section className="testimonials-sec">
      <Container>
        <Row className="testi-main-row">
          <Col lg={6}>
            <div
              className={
                testiImgFour
                  ? "testi-image-slider ecom-testi-image-slider"
                  : testiImgSix
                  ? "testi-image-slider web-testi-image-slider"
                  : testiImgThree
                  ? "testi-image-slider about-testi-image-slider"
                  : "testi-image-slider"
              }
            >
              <img
                src={
                  locationURL === "/about-us"
                    ? testiImgThree
                    : locationURL === "/virtual-assistance"
                    ? testiImgTwo
                    : locationURL === "/ecommerce-virtual-assistance"
                    ? testiImgFour
                    : locationURL === "/creative-design-virtual-assistance"
                    ? testiImgFive
                    : locationURL === "/web-development-virtual-assistance"
                    ? testiImgSix
                    : TestiImg1
                }
                alt="TestiImg1 image"
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="testi-content-slider">
              <div className="testi-content-heading">
                <div className="dot"></div>
                <p>Testimonials</p>
              </div>

              <h2>
                What Our Users Are <span>Saying</span>
              </h2>

              <div className="testi-para-div">
                <p className="testi-para">
                  Discover why users love ConvoBird. Here are some testimonials
                  from our satisfied customers
                </p>
              </div>

              <Slider {...settings}>
                {testiData.map((data) => (
                  <div key={data.id} className="item">
                    <div className="testi-img-content">
                      <p>{data.text}</p>
                      <h3>{data.title}</h3>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Testimonials;
