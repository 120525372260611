import React from "react";
import { Route, Routes } from "react-router-dom";

//import components
import Header from "./components/Header";
import Footer from "./components/Footer";

// import Pages
import Home from "./pages/Home";
import Pricing from "./pages/Pricing";
import VirtualAssistance from "./pages/VirtualAssistance";
import AboutUs from "./pages/About-Us";
import ContactUs from "./pages/Contact-Us";
import EcommerceVA from "./pages/EcommerceVA";
import CreativeDesignVA from "./pages/CreativeDesignVA";
import WebDevelopmentVA from "./pages/WebDevlopmentVA";
import Terms from "./pages/Terms";
import PrivacyPolicy from "./pages/PrivacyPolicy";

const App = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/virtual-assistance" element={<VirtualAssistance />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/ecommerce-virtual-assistance" element={<EcommerceVA />} />
        <Route
          path="/creative-design-virtual-assistance"
          element={<CreativeDesignVA />}
        />
        <Route
          path="/web-development-virtual-assistance"
          element={<WebDevelopmentVA />}
        />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<Home />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
