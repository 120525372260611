import React, { useRef } from "react";
import "./WebDevlopmentVA.css";
import { Helmet } from "react-helmet";

import EcommerceBanner from "../../components/EcommerceBanner";

import CreativeDesignBanner from "../../assets/images/creative-design-banner.png";
import CoveredPlatforms from "../../components/CoveredPlatforms";
import EcommerceBenefits from "../../components/EcommerceBenefits";
import FreeConsultation from "../../components/FreeConsultation";
import EcomServices from "../../components/EcomServices";
import EasiestHire from "../../components/EasiestHire";
import SelectHours from "../../components/SelectHours";
import Testimonials from "../../components/Testimonials";
import StepsComp from "../../components/StepsComp";

import EfficiencyImage from "../../assets/images/efficiency-img.png";
import CodeQualityImg from "../../assets/images/code-quality-img.png";
import StreamlinedImg from "../../assets/images/streamlined-img.png";
import InfoImg from "../../assets/images/info-img.png";
import BirdIcon from "../../assets/images/bird-icon.png";
import testiImgSix from "../../assets/images/testiImg6.png";

const WebDevelopmentVA = () => {
  const servicesRef = useRef(null);
  const stepFormRef = useRef(null);

  const scrollToServicesSec = () => {
    servicesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToStepFormSec = () => {
    stepFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const BannerContent = {
    image: CreativeDesignBanner,
    orangeTitle: "Ecommerce",
    heading1: "Web Design And Development Virtual",
    heading2: "Assistance",
    para: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam",
  };

  const ecommerceBenefitsContent = {
    orangetitle: "Benefits",
    heading1: "Benefits of Getting A Web",
    heading2: "Development",
    heading3: "Virtual Assistant",
    ecomBenefitsContentBox: [
      {
        title: "Increased Efficiency",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam",
        imgSrc: EfficiencyImage,
      },
      {
        title: "Improved Code Quality",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam",
        imgSrc: CodeQualityImg,
      },
      {
        title: "Streamlined Task Management",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam",
        imgSrc: StreamlinedImg,
      },
      {
        title: "Quick Access to Information",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam",
        imgSrc: InfoImg,
      },
    ],
  };

  const ecomServicesContent = {
    orangeTitle: "Services",
    heading1: "What A Web Development",
    heading2: "Virtual Assistant",
    heading3: "Can Do",
    services: [
      {
        title: "Code Assistance",
        subtitle: "& Autocompletion",
        icon: BirdIcon,
        listItems: [
          "Executive assistant",
          "Data entry",
          "Manage files and documents",
          "Update product lists and prices",
          "Manage stock levels and place orders",
          "Accounting",
          "Bookkeeping",
          "Tax preparation",
          "Payroll",
          "Billing",
        ],
      },
      {
        title: "Debugging",
        subtitle: "Support",
        icon: BirdIcon,
        listItems: [
          "Shopify Developer",
          "WordPress Developer",
          "Amazon store setup",
          "Etsy store setup",
          "Managing BigCommerce and other platforms",
          "Walmart store setup",
          "IT support",
          "Identifying and updating broken links",
          "Back-end SEO",
          "Monitoring and improving page load speed",
        ],
      },
      {
        title: "Task",
        subtitle: "Automation",
        icon: BirdIcon,
        listItems: [
          "Placing and updating orders",
          "Order tracking",
          "Handling product returns and exchanges",
          "Processing orders and refunds",
          "Managing returns",
          "Product recommendations",
          "Upselling and cross-selling",
          "Handling customer complaints",
          "Responding to product reviews",
          "24/7 chat support",
        ],
      },
      {
        title: "Documentation",
        subtitle: "Retrieval",
        icon: BirdIcon,
        listItems: [
          "Writing and editing product descriptions",
          "Web Development",
          "Social media management",
          "Writing ad copy",
          "Creating images for ads and social posts",
          "Video editing",
          "Customer research",
          "Creating blog content",
          "Track social media performance",
          "Keyword research",
        ],
      },
    ],
  };

  const stepCompContent = {
    title: "Hire Design VA",
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ConvoBird | Web Development Virtual Assistance</title>
      </Helmet>

      <EcommerceBanner
        content={BannerContent}
        scrollToServicesSec={scrollToServicesSec}
      />
      <CoveredPlatforms />

      <EcommerceBenefits content={ecommerceBenefitsContent} />

      <FreeConsultation scrollToStepFormSec={scrollToStepFormSec} />

      <EcomServices
        pageType="web"
        content={ecomServicesContent}
        ref={servicesRef}
      />

      <EasiestHire />

      <SelectHours />

      <Testimonials testiImgSix={testiImgSix} />

      <StepsComp content={stepCompContent} ref={stepFormRef} />
    </>
  );
};

export default WebDevelopmentVA;
