import React, { useRef } from "react";
import "./VirtualAssistance.css";
import InnerBanner from "../../components/InnerBanner/InnerBanner";
import Testimonials from "../../components/Testimonials";
import FourSteps from "../../components/FourSteps";

import { Col, Container, Image, Row } from "react-bootstrap";

import GlassdoorImg from "../../assets/images/glassdoor.png";
import TrustpilotImg from "../../assets/images/trustpilot.png";
import TBPCLogo from "../../assets/images/tbpc.png";
import GOTODIRECTLogo from "../../assets/images/gotodirect.png";
import techgensoLogo from "../../assets/images/techgenso.png";
import amzLogo from "../../assets/images/amzlogo.png";
import nexusLogo from "../../assets/images/nexusLogo.png";

import PerformanceSec from "../../components/PerformanceSec";
import RecognitionSec from "../../components/RecognitionSec";
import FreeConsultation from "../../components/FreeConsultation";
import VASecurity from "../../components/VASecurity";
import AssistantSec from "../../components/AssistantSec";

import BestTalent from "../../assets/images/best-talent.png";
import FlexibleServices from "../../assets/images/flexible-services.png";
import ChatSupport from "../../assets/images/chat-support.png";
import FullyService from "../../assets/images/fully-service.png";
import PeopleFocus from "../../assets/images/people-focus.png";
import TestiImgTwo from "../../assets/images/testiImg2.png";

import { Helmet } from "react-helmet";

const VirtualAssistance = () => {
  // const assistantSecRef = useRef(null);
  // const assistantSecRef2 = useRef(null);

  // const scrollToAssistantSec = () => {
  //   assistantSecRef.current.scrollIntoView({ behavior: "smooth" });
  // };

  // const scrollToAssistantSec2 = () => {
  //   assistantSecRef2.current.scrollIntoView({ behavior: "smooth" });
  // };
  const overviewSec = useRef(null);
  const fourSteps = useRef(null);

  const scrolltoOverviewSec = () => {
    overviewSec.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrolltoFourSteps = () => {
    fourSteps.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ConvoBird | Virtual Assistance</title>
      </Helmet>
      <InnerBanner scrolltoOverviewSec={scrolltoOverviewSec} />

      <section className="inner-brands-sec">
        <Container>
          <Row className="inner-brands-Row">
            <Col lg={2}>
              <div className="logo-images">
                <Image src={TBPCLogo} alt="TBPCLogo img" />
              </div>
            </Col>

            <Col lg={2}>
              <div className="logo-images1">
                <Image src={GOTODIRECTLogo} alt="GOTODIRECTLogo img" />
              </div>
            </Col>

            <Col lg={2}>
              <div className="logo-images2">
                <Image src={techgensoLogo} alt="techgensoLogo img" />
              </div>
            </Col>

            <Col lg={2}>
              <div className="logo-images3">
                <Image src={amzLogo} alt="amzLogo img" />
              </div>
            </Col>
            <Col lg={2}>
              <div className="logo-images4">
                <Image src={nexusLogo} alt="nexusLogo img" />
              </div>
            </Col>

            <Col lg={2}></Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="inner-brands-content">
                <h2>Brands Using Our Virtual Assistance Services</h2>
                <div className="brands-images-div">
                  <Image src={GlassdoorImg} alt="GlassdoorImg" />
                  <Image src={TrustpilotImg} alt="TrustpilotImg" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <PerformanceSec />
      <RecognitionSec />
      <FreeConsultation scrolltoFourSteps={scrolltoFourSteps} />
      <VASecurity />
      <AssistantSec ref={overviewSec} />

      <section className="home-services-sec inner-services-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="home-services-content inner-services-content">
                <h2>
                  Why ConvoBird Virtual Assistant<span>?</span>
                </h2>
              </div>
            </Col>
          </Row>

          <Row className="services-content-row">
            <Col lg={4} className="services-border">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <Image src={BestTalent} alt="Avatar" />
                    <h2>Best of Best Talent</h2>
                  </div>
                  <div className="flip-card-back">
                    <h2>Best of Best Talent</h2>
                    <p>
                      From startups to scaleups; 90% of our clients are growing
                      businesses.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="services-border">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <Image src={BestTalent} alt="Avatar" />
                    <h2>SMB Friendly</h2>
                  </div>
                  <div className="flip-card-back">
                    <h2>SMB Friendly</h2>
                    <p>
                      Save time on repeated questions quickly save responses to
                      common questions. Reuse them in conversations with just a
                      few keystrokes.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="services-border services-border2">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <Image src={FlexibleServices} alt="Avatar" />
                    <h2>Flexible Service</h2>
                  </div>
                  <div className="flip-card-back">
                    <h2>Flexible Service</h2>
                    <p>
                      Save time on repeated questions quickly save responses to
                      common questions. Reuse them in conversations with just a
                      few keystrokes.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="services-border services-border-bottom">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <Image src={ChatSupport} alt="Avatar" />
                    <h2>Customer Care</h2>
                  </div>
                  <div className="flip-card-back">
                    <h2>Customer Care</h2>
                    <p>
                      Save time on repeated questions quickly save responses to
                      common questions. Reuse them in conversations with just a
                      few keystrokes.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="services-border services-border-bottom">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <Image src={FullyService} alt="Avatar" />
                    <h2>Fully Managed Service</h2>
                  </div>
                  <div className="flip-card-back">
                    <h2>Fully Managed Service</h2>
                    <p>
                      Save time on repeated questions quickly save responses to
                      common questions. Reuse them in conversations with just a
                      few keystrokes.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg={4}
              className="services-border services-border2 services-border-bottom"
            >
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <Image src={PeopleFocus} alt="Avatar" />
                    <h2>People Focused</h2>
                  </div>
                  <div className="flip-card-back">
                    <h2>People Focused</h2>
                    <p>
                      Save time on repeated questions quickly save responses to
                      common questions. Reuse them in conversations with just a
                      few keystrokes.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Testimonials testiImgTwo={TestiImgTwo} />

      <FourSteps ref={fourSteps} />
    </>
  );
};

export default VirtualAssistance;
