import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./AboutBanner.css";

import AboutBannerImg from "../../assets/images/aboutBanner-img.png";
import FreeConsultModal from "../FreeConsultModal";

const InnerBanner = () => {
  return (
    <section className="va-assistance-sec va-assistance-sec2">
      <Container>
        <Row className="va-assistance-row">
          <Col lg={6}>
            <div className="va-assistance-content about-assistance-content">
              <div className="va-small-heading">
                <div className="inner-dot"></div>
                <p className="inner-dot-para">About</p>
              </div>

              <h1>
                Who We <span>Are?</span>
              </h1>

              <p>
                Welcome to ConvoBird, where communication meets innovation! We
                are passionate about creating seamless connections and enhancing
                your digital conversations.
              </p>

              <p>
                We believe in simplifying communication. Our mission is to
                empower individuals and businesses to connect effortlessly,
                fostering meaningful conversations across the globe.
              </p>

              <h3>What Sets Us Apart:</h3>

              <ul>
                <li>
                  We've designed our chat application with simplicity and
                  user-friendliness in mind, ensuring a smooth and enjoyable
                  experience.
                </li>

                <li>
                  From powerful search capabilities to customizable chat
                  settings, we provide tools that elevate your communication
                  experience.
                </li>

                <li>
                  Your privacy is our priority. Our robust security measures
                  ensure that your conversations are protected and secure.
                </li>
              </ul>

              <div className="va-banner-btn">
                <FreeConsultModal learmMore={"Learn More"} />
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="about-banner-img ">
              <img src={AboutBannerImg} alt="AboutBannerImg" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default InnerBanner;
