import React from "react";
import "./FreeConsultation.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const FreeConsultation = ({ scrollToStepFormSec }) => {
  return (
    <section className="potential-lead-sec consultation-lead-sec">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="potential-lead-content">
              <h2>Book Your Free Consultation</h2>
              <p>
                Want to get things moving? Get ready to discover life with a
                ConvoBird VA!
              </p>

              <div className="consultation-btn">
                <Link
                  to={"#"}
                  onClick={scrollToStepFormSec}
                  className="orange-btn"
                >
                  Book Now
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FreeConsultation;
