import React from "react";
import "./PotentialLead.css";
import { Col, Container, Row } from "react-bootstrap";

const PotentialLead = () => {
  return (
    <section className="potential-lead-sec">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="potential-lead-content">
              <h2>Every Visitor Is A Potential Lead!</h2>
              <p>
                Ready to take your business approachability to the next level?
                <br />
                Join ConvoBird and create meaningful connections with your
                customers.
              </p>

              <div className="potential-lead-form">
                <form>
                  <input type="text" placeholder="Enter Business Email Here" />
                  <button>Free Sign Up</button>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PotentialLead;
