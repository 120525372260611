import React, { useRef } from "react";
import "./Pricing.css";
import { Col, Container, Row } from "react-bootstrap";
import PricingComp from "../../components/PricingComp";
import FreeTrial from "../../components/FreeTrial/index";
import TeamSec from "../../components/TeamSec";
import TrustedComp from "../../components/TrustedComp/TrustedComp";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const Pricing = () => {
  const pricingBox = useRef(null);

  const scrollTopricingBoxSec = () => {
    pricingBox.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ConvoBird | Pricing</title>
      </Helmet>
      <section className="pricing-banner">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="pricing-banner-content">
                <h1>Connecting You to Limitless Possibilities!</h1>
                <p>
                  At ConvoBird, we believe in empowering our users with a
                  seamless and feature-rich experience that caters to your
                  unique needs. Our pricing plans are crafted to offer
                  flexibility, scalability, and unbeatable value, ensuring that
                  you get the most out of your investment.
                </p>

                <p>
                  Choose the plan that suits you best and embark on a journey of
                  productivity, collaboration, and success with ConvoBird Let's
                  start your 7 days Free Trial now to revolutionize the way you
                  connect and achieve together!
                </p>

                <div className="pricing-banner-btn">
                  <Link
                    to={"#"}
                    className="orange-btn"
                    onClick={scrollTopricingBoxSec}
                  >
                    Start Your Free Trial
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <PricingComp ref={pricingBox} />

      <TrustedComp />

      <TeamSec />
      <FreeTrial />
    </>
  );
};

export default Pricing;
