import React from "react";
import "./EasiestHire.css";
import { Col, Container, Row } from "react-bootstrap";

import Step1Img from "../../assets/images/step1-img.png";
import Step2Img from "../../assets/images/step2-img.png";
import Step3Img from "../../assets/images/step3-img.png";
import Step4Img from "../../assets/images/step4-img.png";

const EasiestHire = () => {
  return (
    <section className="EasiestHire-sec">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="EasiestHire-content">
              <h2>The Easiest Way to Hire an E-commerce VA</h2>
            </div>
          </Col>

          <Col lg={3} md={6}>
            <div className="easiest-box">
              <h4>Step 1</h4>

              <img src={Step1Img} alt="Step1Img" />

              <h3>QnA</h3>

              <p>
                Let us know the skills and experience you’re looking for. We’ll
                recruit your E-commerce VA specifically to the requirements you
                submit.
              </p>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="easiest-box">
              <h4>Step 2</h4>

              <img src={Step2Img} alt="Step2Img" />

              <h3>Screening</h3>

              <p>
                We’ll pre-screen and provide a short list of the top candidates.
                You review their qualifications. Then, we’ll set up an
                interview.
              </p>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="easiest-box">
              <h4>Step 3</h4>

              <img src={Step3Img} alt="Step3Img" />

              <h3>Onboarding</h3>

              <p>
                Once you’ve found your match, we’ll kick off the onboarding
                process by sending you a schedule request. Our HR team will
                introduce you to your new VA and set up your first meeting.
              </p>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="easiest-box">
              <h4>Step 4</h4>

              <img src={Step4Img} alt="Step4Img" />

              <h3>Manage</h3>

              <p>
                You can manage your billing, upgrades and downgrades directly
                from your tawk.to Dashboard. Congratulations — you’ve
                successfully delegated!
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default EasiestHire;
