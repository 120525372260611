import React from "react";
import "./FreeTrial.css";
import { Col, Container, Row } from "react-bootstrap";

const FreeTrial = ({ url }) => {
  const sectionStyle = url === "/contact-us" ? { margin: 0 } : {};
  return (
    <section className="free-lead-sec" style={sectionStyle}>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="free-lead-content">
              <h2>Start Your Free ConvoBird Trial NOW!</h2>
              <p>
                Get Started Your 7 Days Free Trail Today, Join ConvoBird and
                discover the convenience of having smart experts at your
                fingertips. <br /> Elevate your chat experience with
                intelligence and availability.
              </p>

              <div className="free-lead-form">
                <form>
                  <input type="text" placeholder="Enter Business Email Here" />
                  <button>Start your Free Trail</button>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default FreeTrial;
