import React, { forwardRef } from "react";
import "./StepsComp.css";

import { Col, Container, Image, Row } from "react-bootstrap";

import ReCaptcha from "../../assets/images/re-captcha.png";
import NoContracts from "../../assets/images/no-contracts.png";
import StartingVector from "../../assets/images/starting-vector.png";
import ZonesVector from "../../assets/images/zones-vector.png";

const StepsComp = forwardRef((prop, ref) => {
  const { content } = prop;
  return (
    <section className="FourSteps-sec" ref={ref}>
      <Container>
        <Row>
          <Col lg={6}>
            <div className="FourSteps-content">
              <h2>{content.title}</h2>

              <div className="step-contract-box">
                <img src={NoContracts} alt="no-contracts-img" />

                <div className="step-contract-box-content">
                  <h4>No Contracts</h4>
                  <p>
                    This service operates on a month-to-month basis. You can
                    upgrade, downgrade, or cancel anytime with a one-month
                    notice period.
                  </p>
                </div>
              </div>

              <div className="step-contract-box">
                <img src={StartingVector} alt="no-contracts-img" />

                <div className="step-contract-box-content">
                  <h4>Starting $7/hr</h4>
                  <p>
                    Reduce employee expenses, overhead, and office costs while
                    increasing productivity. Enjoy additional time for strategic
                    focus and higher-level tasks.
                  </p>
                </div>
              </div>

              <div className="step-contract-box">
                <img src={ZonesVector} alt="no-contracts-img" />

                <div className="step-contract-box-content">
                  <h4>Cover All Time Zones</h4>
                  <p>
                    Our team members serve in all time zones. Need to assist a
                    customer at 3am? We’ve got you covered.
                  </p>
                </div>
              </div>
            </div>
          </Col>

          <Col lg={6}>
            <div className="FourSteps-form-div contract-form-div">
              <div className="FourSteps-form-content">
                <h3>Let’s Get Started</h3>

                <div className="FourSteps-para">
                  <p>
                    Fill out the form below and mention which skills you need.
                  </p>

                  <p>We will be in touch to discuss your ideal candidate.</p>
                </div>
              </div>

              <div className="FourStep-form-content">
                <form>
                  <input type="text" placeholder="Full Name*" />
                  <input type="text" placeholder="Business Email*" />
                  <input type="text" placeholder="Phone*" />
                  <input type="text" placeholder="Company Name*" />
                  <input type="text" placeholder="Company URL*" />

                  <textarea placeholder="Enter required skills (e.g. Chat Answering, Voice Calls, Data Entry, Customer Service, Tech Support, Email Support, etc.)"></textarea>

                  <div className="FourSteps-submit-btn">
                    <button>Submit</button>
                  </div>
                </form>

                <div className="re-captcha-div">
                  <Image src={ReCaptcha} alt="re-captcha-image" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
});

export default StepsComp;
