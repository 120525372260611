import React from "react";
import "./PackageMap.css";
import { Col, Container, Row } from "react-bootstrap";

const PackageMap = () => {
  return (
    <section className="package-map-sec">
      <Container>
        <Row>
          <Col lg={4} md={4}>
            <div className="package-map-content">
              <h3>125M</h3>
            </div>

            <div className="package-map-text">
              <p>CHATS MONTHLY</p>
            </div>
          </Col>
          <Col lg={4} md={4}>
            <div className="package-map-content">
              <h3>125</h3>
            </div>

            <div className="package-map-text">
              <p>COUNTRIES CONNECTED</p>
            </div>
          </Col>
          <Col lg={4} md={4}>
            <div className="package-map-content">
              <h3>125K</h3>
            </div>

            <div className="package-map-text">
              <p>SUPPORT REPS</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PackageMap;
