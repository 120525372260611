import React from "react";
import "./Footer.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

// import { FaFacebookF } from "react-icons/fa";
// import { FaInstagram } from "react-icons/fa";
// import { FaLinkedinIn } from "react-icons/fa";
// import { FaXTwitter } from "react-icons/fa6";
// import { FaYoutube } from "react-icons/fa";

import FooterImg from "../../assets/images/logo.png";
import PaymentImg from "../../assets/images/payment.png";

const Footer = () => {
  const date = new Date();

  const year = date.getFullYear();
  return (
    <footer className="footer-sec">
      <Container>
        <Row className="footer-row">
          <Col lg={3} md={6}>
            <div className="footer-link-div">
              <p>Services</p>

              <ul>
                <li>
                  <Link to={"/virtual-assistance"}>Virtual Assistance</Link>
                </li>

                <li>
                  <Link to={"/ecommerce-virtual-assistance"}>
                    Ecommerce Virtual Assistance
                  </Link>
                </li>

                <li>
                  <Link to={"/creative-design-virtual-assistance"}>
                    Creative Design Virtual Assistance
                  </Link>
                </li>

                <li>
                  <Link to={"/web-development-virtual-assistance"}>
                    Web Development Virtual Assistance
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="footer-link-div">
              <p>Pricing</p>

              <ul>
                <li>
                  <Link to={"/pricing"}>Pricing Plan</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="footer-link-div">
              <p>About</p>

              <ul>
                <li>
                  <Link to={"/about-us"}>About Us</Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="footer-link-div">
              <p>Contact</p>

              <ul>
                <li>
                  <Link to={"/contact-us"}>Contact Us</Link>
                </li>
              </ul>
            </div>
          </Col>
        </Row>

        <Row className="footer-row-two">
          <Col lg={12}>
            <div className="footer-content-main">
              <div>
                <div className="footer-content">
                  <Link to={"/"}>
                    <img src={FooterImg} alt="footer img" />
                  </Link>
                </div>
              </div>

              <div className="footer-terms-link">
                <div className="footer-paymentImg">
                  <img src={PaymentImg} alt="paymentImg" />
                </div>

                <div className="footer-term">
                  <Link to={"/terms-and-conditions"}>Terms of Use</Link>
                </div>

                <div className="footer-term">
                  <Link to={"/privacy-policy"}>Privacy Policy</Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="footer-row-three">
          <Col lg={12}>
            <div className="footer-copyright">
              <p>Copyright © {year} ConvoBird. All Rights Reserved.</p>
            </div>
            <div className="footer-disclaimer">
              <p>
                Customer service software that adapts to your business needs.
                Whether you’re a B2B SaaS or an eCommerce, ConvoBird will help
                you boost your support and sales across multiple communication
                channels. Copyright © {year} ConvoBird. All rights reserved.
              </p>

              <p>
                Hi! We are glad to have you on our website! Before you start
                using our website, please note that we use cookies and similar
                technologies to enhance your website experience, analyze traffic
                and usage, personalize content to your preferences, and assist
                in our marketing efforts. By using our website, you consent to
                allow us, and our partners, to collect, use, retrieve, and store
                cookies and similar technologies on your computer or other
                devices. You can revoke your consent at any time in your
                computer/device browser settings. Click the Cookies Policy to
                check how you can control the use of cookies through your
                device. Your data will be processed in accordance with our
                Privacy Policy.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
