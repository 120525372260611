import React from "react";
import "./EcommerceBenefits.css";
import { Col, Container, Row } from "react-bootstrap";

const EcommerceBenefits = ({ content }) => {
  return (
    <section className="ecom-benefits">
      <div className="benefits-vector1"></div>
      <div className="benefits-vector2"></div>
      <div className="benefits-vector3"></div>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="ecom-benefits-content">
              <div className="va-small-heading">
                <div className="inner-dot"></div>
                <p className="inner-dot-para">{content.orangetitle}</p>
              </div>

              <h2>
                {content.heading1}
                <span>
                  {content.heading2}
                  <span className="yellowSpan"> {content.heading3}</span>
                </span>
              </h2>
            </div>
            <div className="ecom-benefits-contentBox">
              <Row className="ecom-benefits-content-row">
                {content.ecomBenefitsContentBox.map((benefit, index) => (
                  <React.Fragment key={index}>
                    {index % 2 === 0 ? (
                      <>
                        <Col lg={6} md={6}>
                          <div
                            className={`benefits-content benefits-content${
                              index + 1
                            }`}
                          >
                            <h4>{benefit.title}</h4>
                            <p>{benefit.description}</p>
                          </div>
                        </Col>
                        <Col lg={6} md={6}>
                          <div
                            className={`benefits-img benefits-img${index + 1}`}
                          >
                            <img
                              src={benefit.imgSrc}
                              alt={`Benefit ${index + 1}`}
                            />
                          </div>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col lg={6} md={6}>
                          <div
                            className={`benefits-img benefits-img${index + 1}`}
                          >
                            <img
                              src={benefit.imgSrc}
                              alt={`Benefit ${index + 1}`}
                            />
                          </div>
                        </Col>
                        <Col lg={6} md={6}>
                          <div
                            className={`benefits-content benefits-content${
                              index + 1
                            }`}
                          >
                            <h4>{benefit.title}</h4>
                            <p>{benefit.description}</p>
                          </div>
                        </Col>
                      </>
                    )}
                  </React.Fragment>
                ))}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default EcommerceBenefits;
