import React, { useState, useEffect } from "react";
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Image,
  Row,
  Col,
} from "react-bootstrap";

import { Link, useLocation, useNavigate } from "react-router-dom";

import LogoImg from "../../assets/images/logo.png";
import "./header.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const handleTitleHover = () => {
    setIsOpen(true);
  };

  const handleDropdownClose = () => {
    setIsOpen(false);
  };

  const location = useLocation();
  const navigate = useNavigate();

  const currentURL = location.pathname;

  const handleTitleClick = (event) => {
    const isTitleClicked = event.target.parentNode === event.currentTarget;

    if (isTitleClicked) {
      navigate("/virtual-assistance");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.querySelector(".header");
      const scrollY = window.scrollY;

      if (scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount

  const headerClass = isSticky ? "header sticky" : "header";

  return (
    <header className={headerClass}>
      <Container>
        <Row>
          <Col lg={12}>
            <Navbar collapseOnSelect expand="lg">
              <Navbar.Brand as={Link} to="/" className="nav-logo-div">
                <Image src={LogoImg} alt="logo image" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="me-auto navbar-left">
                  <NavDropdown
                    show={isOpen}
                    as={"div"}
                    title="VA Services"
                    id="collapsible-nav-dropdown"
                    className="nav-drop"
                    onClick={handleTitleClick}
                    onMouseEnter={handleTitleHover}
                    onMouseLeave={handleDropdownClose}
                  >
                    <NavDropdown.Item
                      as={Link}
                      to="/ecommerce-virtual-assistance"
                    >
                      Ecommerce Virtual Assistance
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/web-development-virtual-assistance"
                    >
                      Web Development Virtual Assistance
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/creative-design-virtual-assistance"
                    >
                      Creative Design Virtual Assistance
                    </NavDropdown.Item>
                  </NavDropdown>

                  <Nav.Link as={Link} to="/pricing" className="nav-links">
                    Pricing
                  </Nav.Link>

                  <Nav.Link as={Link} to="/about-us" className="nav-links">
                    About Us
                  </Nav.Link>

                  <Nav.Link as={Link} to="/contact-us" className="nav-links">
                    Contact Us
                  </Nav.Link>
                </Nav>
                <Nav className="navbar-right">
                  <Nav.Link
                    as={Link}
                    to="https://onechat.ledgecrm.com/"
                    className="login-links"
                    target="_blank"
                  >
                    Login
                  </Nav.Link>
                  <Nav.Link
                    eventKey={2}
                    as={Link}
                    to="https://onechat.ledgecrm.com/"
                    // className="signup-link"
                    target="_blank"
                    className={
                      currentURL === "/pricing" ? "signup-link2" : "signup-link"
                    }
                  >
                    Sign Up
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
