import React from "react";
import "./RecognitionSec.css";
import { Col, Container, Image, Row } from "react-bootstrap";

import TelegraphImg from "../../assets/images/telegraph.png";
import BBCImg from "../../assets/images/bbc.png";
import CosmopolitanImg from "../../assets/images/cosmopolitan.png";
import SundayTimes from "../../assets/images/sunday-times.png";
import TheMail from "../../assets/images/themail.png";

const RecognitionSec = () => {
  return (
    <section className="recognition-sec">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="recognition-content">
              <div className="va-small-heading">
                <div className="inner-dot"></div>
                <p className="inner-dot-para">Recognition</p>
              </div>

              <h2>As Featured In:</h2>

              <div className="recognition-img-div">
                <Image src={TelegraphImg} alt="TelegraphImg" />
                <Image src={BBCImg} alt="BBCImg" />
                <Image src={CosmopolitanImg} alt="CosmopolitanImg" />
                <Image src={SundayTimes} alt="SundayTimes" />
                <Image src={TheMail} alt="TheMail" />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RecognitionSec;
