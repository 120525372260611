import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import TKImg from "../../assets/images/TK.png";
import D_Img from "../../assets/images/D_Img.png";
import vitals_logo from "../../assets/images/vitals_logo.png";
import trusted_logo from "../../assets/images/trusted-logo3.png";
import web_developer_agency from "../../assets/images/web_developer_agency.png";

import "./TrustedComp.css";

const TrustedComp = () => {
  return (
    <section className="world-companies">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="world-companies-content">
              <h3>Trusted by companies worldwide</h3>
            </div>

            <div className="world-companies-img">
              <img src={TKImg} alt="TKImg" />
              <img src={D_Img} alt="D_Img" />
              <img src={vitals_logo} alt="vitals_logo" />
              <img src={trusted_logo} alt="trusted_logo" />
              <img src={web_developer_agency} alt="web_developer_agency" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TrustedComp;
