import React from "react";
import "./ClientSec.css";

import clientImgOne from "../../assets/images/paragon.png";
import clientImgTwo from "../../assets/images/tradekor.png";
import clientImgThree from "../../assets/images/welisto-vitals-logo.png";
import clientImgFour from "../../assets/images/wrinkles-gone.png";
import clientImgFive from "../../assets/images/Web_Developers_Agency.png";
import clientImgSix from "../../assets/images/Thrifty_Logo.png";
import clientImgSeven from "../../assets/images/Gold_Republica_Logo.png";
import clientImgEight from "../../assets/images/design-support-desk.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Col, Container, Row } from "react-bootstrap";

const ClientSec = () => {
  const sliderOneImages = [
    {
      id: 1,
      imageUrl: clientImgOne,
      altTitle: "clientImgOne",
    },

    {
      id: 2,
      imageUrl: clientImgTwo,
      altTitle: "clientImgTwo",
    },

    {
      id: 3,
      imageUrl: clientImgThree,
      altTitle: "clientImgThree",
    },

    {
      id: 4,
      imageUrl: clientImgFour,
      altTitle: "clientImgFour",
    },
  ];

  const sliderTwoImages = [
    {
      id: 1,
      imageUrl: clientImgFive,
      altTitle: "clientImgFive",
    },

    {
      id: 2,
      imageUrl: clientImgSix,
      altTitle: "clientImgSix",
    },

    {
      id: 3,
      imageUrl: clientImgSeven,
      altTitle: "clientImgSeven",
    },

    {
      id: 4,
      imageUrl: clientImgEight,
      altTitle: "clientImgEight",
    },
  ];

  const sliderOne = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    rtl: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const sliderTwo = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    rtl: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="client-sec">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="client-sec-content">
              <div className="home-solution">
                <div className="dot"></div>
                <p>clientele</p>
              </div>
              <h2>Industry Leaders Using ConvoBird</h2>
            </div>

            <div className="client-slider-one">
              <Slider {...sliderOne}>
                {sliderOneImages.map((data) => {
                  return (
                    <div className="item" key={data.id}>
                      <div className="client-slider-img-div">
                        <img src={data.imageUrl} alt={data.altTitle} />
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>

            <div className="client-slider-one">
              <Slider {...sliderTwo}>
                {sliderTwoImages.map((data) => {
                  return (
                    <div className="item" key={data.id}>
                      <div className="client-slider-img-div">
                        <img src={data.imageUrl} alt={data.altTitle} />
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ClientSec;
