import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { HiCheck } from "react-icons/hi";

import bannerImg from "../../assets/images/banner-img.png";
import TKImg from "../../assets/images/TK.png";
import D_Img from "../../assets/images/D_Img.png";
import vitals_logo from "../../assets/images/vitals_logo.png";
import trusted_logo from "../../assets/images/trusted-logo3.png";
import web_developer_agency from "../../assets/images/web_developer_agency.png";

import "./HomeBanner.css";

const HomeBanner = () => {
  return (
    <section className="home-banner">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="home-banner-left">
              <div className="home-banner-content">
                <div className="home-solution">
                  <div className="dot"></div>
                  <p>Where every message is an opportunity to connect!</p>
                </div>

                <h1>
                  Chat Customers Like <span>In-Person</span>
                </h1>

                <p>
                  ConvoBird is not just a customer service platform—it's a
                  game-changer for your business approachability. Elevate your
                  brand with our chat-based solution that makes connecting with
                  your customers seamless and enjoyable.
                </p>

                <p>
                  Instant Accessibility: Be available to your customers 24/7,
                  fostering a sense of approachability.
                </p>

                <p>
                  Human Touch: Connect on a personal level with real-time
                  conversations that build lasting relationships.
                </p>

                <p>
                  Effortless Communication: Streamline interactions with an
                  intuitive chat interface that enhances user experience.
                </p>
              </div>

              <div className="banner-form">
                <form>
                  <input
                    type="text"
                    name="email"
                    placeholder="Enter Business Email Here"
                  />

                  <button type="submit">Free Sign Up</button>
                </form>

                <div className="form-guarentee">
                  <p>
                    <HiCheck
                      color="#2C4856"
                      fontWeight={"900"}
                      fontSize={"18px"}
                    />
                    Free 7-Day Trial
                  </p>

                  <p>
                    <HiCheck
                      color="#2C4856"
                      fontWeight={"900"}
                      fontSize={"18px"}
                    />
                    Automation
                  </p>

                  <p>
                    <HiCheck
                      color="#2C4856"
                      fontWeight={"900"}
                      fontSize={"18px"}
                    />
                    Omnichannel Messaging
                  </p>
                </div>
              </div>

              <div className="banner-brands">
                <div className="banner-brands-content">
                  Trusted by <span>companies</span>
                </div>

                <div className="trusted-companies">
                  <img src={TKImg} alt="TKImg" />
                  <img src={D_Img} alt="D_Img" />
                  <img src={vitals_logo} alt="vitals_logo" />
                  <img src={trusted_logo} alt="trusted_logo" />
                  <img src={web_developer_agency} alt="web_developer_agency" />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="home-banner-right">
              <img src={bannerImg} alt="bannerImg" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default HomeBanner;
