import React, { useState } from "react";
import HomeBanner from "../../components/HomeBanner";
import PotentialLead from "../../components/PotentialLead";
import PackageMap from "../../components/PackageMap";
import Testimonials from "../../components/Testimonials";
import ClientSec from "../../components/ClientSec";
import TeamSec from "../../components/TeamSec";
import FreeTrial from "../../components/FreeTrial";

import { Col, Container, Image, Row } from "react-bootstrap";

import eyeImg from "../../assets/images/service-eye.png";
import serviceArchives from "../../assets/images/service-archives.png";
import serviceChat from "../../assets/images/service-chat.png";
import serviceInactivity from "../../assets/images/service-inactivity.png";
import serviceTags from "../../assets/images/service-tags.png";
import SliderOne from "../../assets/images/about-slider-one.png";
import SliderTwo from "../../assets/images/about-slider-two.png";
import SliderThree from "../../assets/images/about-slider-three.png";
import SliderFour from "../../assets/images/about-slider-four.png";
import SliderFive from "../../assets/images/about-slider-five.png";
import SliderSix from "../../assets/images/about-slider-six.png";
import SliderSeven from "../../assets/images/about-slider-seven.png";

import tickImg from "../../assets/images/tick-shape.png";
import tickDimImg from "../../assets/images/tick-shape-dim.png";
import LockImg from "../../assets/images/lock.png";
import visitor from "../../assets/images/visitor.png";
import IPBanning from "../../assets/images/ip-banning.png";

import "./Home.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Home = () => {
  const [activeSlide, setActiveSlide] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (currentSlide) => {
      setActiveSlide(currentSlide);
    },
    // autoplay: true,
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ConvoBird | Home</title>
      </Helmet>
      <HomeBanner />
      <section className="home-services-sec">
        <div className="service-circle-one"></div>
        <div className="service-circle-two"></div>
        <Container>
          <Row>
            <Col lg={12}>
              <div className="home-services-content">
                <h2>With ConvoBird You Get</h2>
              </div>
            </Col>
          </Row>

          <Row className="services-content-row">
            <Col lg={4} className="services-border">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <Image src={eyeImg} alt="Avatar" />
                    <h2>Messages Sneak-Peek</h2>
                  </div>
                  <div className="flip-card-back">
                    <h2>Messages Sneak-Peek</h2>
                    <p>
                      Reply faster, once customers are typing is visible to you
                      before they send it over. You can search for an answer
                      faster and respond in seconds.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="services-border">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <Image src={serviceChat} alt="Avatar" />
                    <h2>Canned Responses</h2>
                  </div>
                  <div className="flip-card-back">
                    <h2>Canned Responses</h2>
                    <p>
                      Save time on repeated questions quickly save responses to
                      common questions. Reuse them in conversations with just a
                      few keystrokes.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="services-border services-border2">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <Image src={serviceInactivity} alt="Avatar" />
                    <h2>Chat History</h2>
                  </div>
                  <div className="flip-card-back">
                    <h2>Chat History</h2>
                    <p>
                      Reduce repeated questions your customers can pick up a
                      chat where they last left off or reread a conversation
                      anytime they need to. It saves time because they do not
                      need to ask again about something you have already
                      answered.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="services-border services-border-bottom">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <Image src={serviceTags} alt="Avatar" />
                    <h2>Inactivity Messages</h2>
                  </div>
                  <div className="flip-card-back">
                    <h2>Inactivity Messages</h2>
                    <p>
                      never lose the leads again Stay in touch with customers
                      through CRM or ask for email if a visitor has not heard
                      from an agent after a specified amount of time, you can
                      still keep your lead engaged by asking for their email
                      address.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="services-border services-border-bottom">
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <Image src={eyeImg} alt="Avatar" />
                    <h2>Chat Tags</h2>
                  </div>
                  <div className="flip-card-back">
                    <h2>Chat Tags</h2>
                    <p>
                      Keep track of every conversation Add tags to your chats to
                      give them context. You will always know what your
                      customers talk about and what problems they face .
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col
              lg={4}
              className="services-border services-border2 services-border-bottom"
            >
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front">
                    <Image src={serviceArchives} alt="Avatar" />
                    <h2>Chat Archives</h2>
                  </div>
                  <div className="flip-card-back">
                    <h2>Chat Archives</h2>
                    <p>
                      find past conversations all chats are stored and can be
                      accessed later on. Use filters to quickly find
                      conversations that include a certain phrase.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="home-about-sec">
        <Container>
          <Row>
            <Col lg={6}>
              <div>
                <Slider {...settings}>
                  <div className="item">
                    <div className="slider-contentDiv">
                      <Image src={SliderOne} alt="SliderOne" />
                      <div className="slider-contentDiv-inner slider-contentDiv-inner1">
                        <p>
                          Engaging the customers with interactive messages,
                          Interact with specific visitors based on their profile
                          and behavior, and send them personalized messages with
                          relevant information.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="slider-contentDiv">
                      <Image src={SliderTwo} alt="SliderTwo" />
                      <div className="slider-contentDiv-inner slider-contentDiv-inner2">
                        <p>
                          Reduce repeated questions your customers can pick up a
                          chat where they last left off or reread a conversation
                          anytime they need to. It saves time because they do
                          not need to ask again about something you have already
                          answered.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="slider-contentDiv">
                      <Image src={SliderThree} alt="SliderThree" />
                      <div className="slider-contentDiv-inner slider-contentDiv-inner3">
                        <p>
                          All agents receive a notification when a visitor wants
                          to start a chat. Agents then can select the unassigned
                          chat from the queue.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="slider-contentDiv">
                      <Image src={SliderFour} alt="SliderFour" />
                      <div className="slider-contentDiv-inner slider-contentDiv-inner4">
                        <p>
                          Handle all chats with ease Chats can be automatically
                          routed to agents or selected manually from the queue.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="slider-contentDiv">
                      <Image src={SliderFive} alt="SliderFive" />
                      <div className="slider-contentDiv-inner slider-contentDiv-inner5">
                        <p>
                          Get messages after hours, when you log off, the chat
                          widget on your website is replaced with a ticket form.
                          Visitors can reach out around the clock and you can
                          handle the ticket when it is convenient for you.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="slider-contentDiv">
                      <Image src={SliderSix} alt="SliderSix" />
                      <div className="slider-contentDiv-inner slider-contentDiv-inner6">
                        <p>
                          Adjust the look and feel of the chat widget. Add as an
                          eye-catcher to promote and get more sales
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="slider-contentDiv">
                      <Image src={SliderSeven} alt="SliderSeven" />
                      <div className="slider-contentDiv-inner slider-contentDiv-inner7">
                        <p>Connect customers with your team members</p>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </Col>
            <Col lg={6}>
              <div className="home-about-content">
                <div className="about-content-top">
                  <div className="highlight-para">
                    <div></div> customer first
                  </div>

                  <h2>
                    All About <span>User Journey</span>
                  </h2>

                  <p>
                    Never miss an opportunity and give seamless experience to
                    your visitors.
                  </p>

                  <ul>
                    <li
                      className={activeSlide === 0 ? "activeList" : "dim-list"}
                    >
                      <Image
                        src={activeSlide === 0 ? tickImg : tickDimImg}
                        alt="tick image"
                      />
                      Targeted Messages
                    </li>
                    <li
                      className={activeSlide === 1 ? "activeList" : "dim-list"}
                    >
                      <Image
                        src={activeSlide === 1 ? tickImg : tickDimImg}
                        alt="tick image"
                      />
                      Routing Rules
                    </li>
                    <li
                      className={activeSlide === 2 ? "activeList" : "dim-list"}
                    >
                      <Image
                        src={activeSlide === 2 ? tickImg : tickDimImg}
                        alt="tick image"
                      />
                      Customize the Chat Widget
                    </li>
                    <li
                      className={activeSlide === 3 ? "activeList" : "dim-list"}
                    >
                      <Image
                        src={activeSlide === 3 ? tickImg : tickDimImg}
                        alt="tick image"
                      />
                      Ticket Form
                    </li>
                    <li
                      className={activeSlide === 4 ? "activeList" : "dim-list"}
                    >
                      <Image
                        src={activeSlide === 4 ? tickImg : tickDimImg}
                        alt="tick image"
                      />
                      Chat Assignment
                    </li>
                    <li
                      className={activeSlide === 5 ? "activeList" : "dim-list"}
                    >
                      <Image
                        src={activeSlide === 5 ? tickImg : tickDimImg}
                        alt="tick image"
                      />
                      Manual Chat Routing
                    </li>
                    <li
                      className={activeSlide === 6 ? "activeList" : "dim-list"}
                    >
                      <Image
                        src={activeSlide === 6 ? tickImg : tickDimImg}
                        alt="tick image"
                      />
                      Chat History
                    </li>
                  </ul>

                  {/* <div className="learn-more-div">
                    <Link to={"https://onechat.ledgecrm.com/"} target="_blank">
                      Learn More
                    </Link>
                  </div> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="home-security-sec">
        <Container>
          <Row className="security-parent-row">
            <Col lg={6}>
              <div className="home-security-content">
                <div className="highlight-para">
                  <div></div> security
                </div>

                <h2>
                  Security <span>Trio</span>
                </h2>

                <p>
                  Never miss an opportunity and give seamless experience to your
                  visitors.
                </p>
                <p>Transform the way you engage with your customers.</p>
                <p>
                  ConvoBird is designed to make your business more approachable,
                  leading to increased customer satisfaction and loyalty.
                </p>
                <div className="learn-more-div">
                  <Link to={"https://onechat.ledgecrm.com/"} target="_blank">
                    Learn More
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="home-security-box">
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <div className="home-security-box-content">
                      <div className="home-security-box-img">
                        <img src={LockImg} alt="LockImg" />
                      </div>

                      <div className="home-security-text">
                        <h3>Encrypted Data</h3>
                        <p>
                          Each connection to chat servers is encrypted with
                          256bit SSL protocol, both for agents and customers.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <div className="home-security-box-content">
                      <div className="home-security-box-img">
                        <img src={visitor} alt="visitor img" />
                      </div>

                      <div className="home-security-text">
                        <h3>Visitor Banning</h3>
                        <p>
                          Get rid of spam chats Agents can easily deal with
                          disruptive visitors by banning them from starting a
                          chat on your website.
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <div className="home-security-box2-content">
                      <h3>More In Progress</h3>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <div className="home-security-box-content">
                      <div className="home-security-box-img">
                        <img src={IPBanning} alt="IPBanning img" />
                      </div>

                      <div className="home-security-text">
                        <h3>IP Banning</h3>
                        <p>
                          Get rid of spam chats Agents can easily deal with
                          disruptive visitors by banning them from starting a
                          chat on your website.
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <PotentialLead />
      <PackageMap />
      <Testimonials />
      <ClientSec />
      <TeamSec />
      <FreeTrial />
    </>
  );
};

export default Home;
