import React from "react";
import "./EcommerceSupport.css";
import { Col, Container, Row } from "react-bootstrap";

import FreeConsultModal from "../FreeConsultModal";

import EcommerceSupportLady from "../../assets/images/ecommerce-support-lady.png";

const EcommerceSupport = ({ learmMore }) => {
  return (
    <section className="ecommerce-support">
      <Container>
        <Row className="support-Row">
          <Col lg={6}>
            <div className="support-img">
              <img src={EcommerceSupportLady} alt="EcommerceSupportLady Img" />
            </div>
          </Col>

          <Col lg={6}>
            <div className="support-sec-content">
              <div className="home-solution">
                <div className="dot"></div>
                <p>Ecommerce</p>
              </div>
              <h2>
                Support <span>24/7</span>
              </h2>
              <p>
                At ConvoBird, We understand the importance of seamless
                communication. That's why our support team is available around
                the clock to assist you. Whether you have a question, encounter
                an issue, or just want to say hello— we are here for you.
              </p>
              <h3>Our Commitment:</h3>
              <p>
                Reach out to us anytime, day or night, and experience prompt
                support.
              </p>
              <p>
                Our dedicated team is well equipped to handle any technical
                queries or concerns.
              </p>
              <p>
                Your satisfaction is our priority. We are committed to ensuring
                your chat experience is smooth and enjoyable.
              </p>
              <div className="teamSec-btn-div">
                <FreeConsultModal learmMore={learmMore} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default EcommerceSupport;
