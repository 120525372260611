import React from "react";
import "./PrivacyPolicy.css";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ConvoBird | Privacy Policy</title>
      </Helmet>
      <section className="terms-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="terms-content">
                <h1>Privacy Policy</h1>

                <div className="terms-content-div">
                  <h3>1. Overview</h3>
                  <p>
                    Welcome to ConvoBird. This Privacy Policy explains how we
                    collect, use, disclose, and safeguard your personal
                    information. By using our application, you agree to the
                    terms outlined in this Privacy Policy.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>2. Information We Collect</h3>
                  <p>
                    a. <b>Personal Information</b>: We may collect personal
                    information, such as your name, email address, and other
                    identifiable information when you register or use our
                    application.
                  </p>

                  <p>
                    b. <b>Usage Data</b>: We may collect information about how
                    you use our application, including your interactions with
                    features and content.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>3. How We Use Your Information</h3>
                  <p>
                    We use the collected information for the following purposes:
                  </p>
                  <p>a. To provide and maintain our application.</p>
                  <p>b. To improve, personalize, and expand our application.</p>
                  <p>
                    c. To communicate with you, including responding to
                    inquiries, providing updates, and offering customer support.
                  </p>
                  <p>
                    d. To send you marketing communications if you have opted in
                    to receive them.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>4. Data Sharing and Disclosure</h3>
                  <p>
                    a. <b>Third-Party Service Providers</b>: We may share your
                    information with third-party service providers to facilitate
                    our services, perform services on our behalf, or assist us
                    in analyzing how our application is used.
                  </p>

                  <p>
                    b. <b>Legal Requirements</b>: We may disclose your
                    information if required to do so by law or in response to a
                    valid legal request.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>5. Security</h3>
                  <p>
                    We take reasonable measures to protect your personal
                    information from unauthorized access, disclosure,
                    alteration, and destruction. However, no method of
                    transmission over the internet or electronic storage is
                    completely secure, and we cannot guarantee absolute
                    security.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>6. Your Choices</h3>
                  <p>
                    a. <b>Opt-Out</b>: You can opt-out of receiving marketing
                    communications from us by following the instructions
                    provided in the communication.
                  </p>
                  <p>
                    b. <b>Access and Correction</b>: You may have the right to
                    access and correct your personal information. Please contact
                    us if you wish to exercise these rights.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>7. Children's Privacy</h3>
                  <p>
                    Our application is not directed to individuals under the age
                    of 13. If you are a parent or guardian and believe that your
                    child has provided us with personal information, please
                    contact us.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>8. Changes to this Privacy Policy</h3>
                  <p>
                    We may update this Privacy Policy from time to time. The
                    updated policy will be posted on our website, and the date
                    of the latest revision will be indicated.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>9. Contact Information</h3>
                  <p>
                    If you have any questions or concerns about this Privacy
                    Policy, please contact us at info@convobird.com
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default PrivacyPolicy;
