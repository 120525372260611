import React, { forwardRef } from "react";
import "./EcomServices.css";
import { Container, Row, Col } from "react-bootstrap";
import SaleModal from "../SaleModal";

const EcomServices = forwardRef((props, ref) => {
  const { content, pageType } = props;

  return (
    <section className="ecom-services-sec" ref={ref} id="ecomServices-sec">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="ecom-services-content">
              <div className="va-small-heading">
                <div className="inner-dot"></div>
                <p className="inner-dot-para">{content.orangeTitle}</p>
              </div>
              <h2>
                {content.heading1}
                <span>
                  {content.heading2}
                  <span className="yellowSpan"> {content.heading3}</span>
                </span>
              </h2>
            </div>
          </Col>
        </Row>
        <Row>
          {content &&
            content.services.map((service, index) => (
              <Col
                lg={6}
                key={index}
                className={
                  index % 2 !== 0 ? `${pageType}-services-col-border` : ""
                }
              >
                <div
                  className={`ecom-services-box ecom-services-box${
                    index + 1
                  } ${pageType}-services-box${index + 1}`}
                >
                  <div
                    className={`vector-icon-before vector-icon-before${
                      index + 1
                    } ${pageType}-icon-before${index + 1}`}
                  ></div>
                  <h3>
                    {service.title} <span>{service.subtitle}</span>
                  </h3>

                  <ul>
                    {service.listItems.map((item, i) => (
                      <li key={i}>
                        <img src={service.icon} alt={`BirdIconImg${i}`} />{" "}
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            ))}
          <Col lg={12}>
            <div className="ecom-services-btn">
              <SaleModal />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
});

export default EcomServices;
