import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "./PerformanceSec.css";

import ExperienceImg from "../../assets/images/experience-img.png";
import CustomizedImg from "../../assets/images/customized-img.png";
import SupportImg from "../../assets/images/support-img.png";

const PerformanceSec = () => {
  return (
    <section className="performance-sec">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="performance-listing-main">
              <div className="performance-listing">
                <div className="performance-listing-content">
                  <Image src={ExperienceImg} alt="ExperienceImg" />

                  <div className="performance-img-content">
                    <h3>Experienced Professionals</h3>
                    <p>
                      Hire A Virtual Assistant Who Understands The Demands Of
                      Working In The Way You Do.
                    </p>
                  </div>
                </div>
              </div>

              <div className="performance-listing">
                <div className="performance-listing-content">
                  <Image src={CustomizedImg} alt="CustomizedImg" />

                  <div className="performance-img-content">
                    <h3>Customized Match</h3>
                    <p>
                      We’ll Match You With A Vetted, Trusted Virtual Assistant
                      With An Average Of 15 Years’ Relevant Experience.
                    </p>
                  </div>
                </div>
              </div>

              <div className="performance-listing">
                <div className="performance-listing-content">
                  <Image src={SupportImg} alt="SupportImg" />

                  <div className="performance-img-content">
                    <h3>Premium Support 24/7</h3>
                    <p>
                      Stuck? Your Dedicated Account Manager Has Got Your Back.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="productivity-content">
              <div className="va-small-heading">
                <div className="inner-dot"></div>
                <p className="inner-dot-para">Performance</p>
              </div>

              <h2>
                Maximize <span>Productivity</span>
              </h2>

              <p>ConvoBird combine talent with tenacity!</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PerformanceSec;
