import React from "react";
import "./Terms.css";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";

const Terms = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ConvoBird | Terms and Conditions</title>
      </Helmet>
      <section className="terms-sec">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="terms-content">
                <h1>Terms of Use</h1>

                <div className="terms-content-div">
                  <h3>1. Acceptance of Terms</h3>
                  <p>
                    By accessing or using the ConvoBird ("the App"), you agree
                    to comply with and be bound by these Terms of Use. If you do
                    not agree with any part of these terms, you may not use the
                    App.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>2. User Eligibility</h3>
                  <p>
                    You must be at least 18 years old to use the App. By using
                    the App, you represent and warrant that you are 18 years of
                    age or older.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>3. Privacy Policy</h3>
                  <p>
                    Your use of the App is also governed by our Privacy Policy,
                    which can be found [here]. By using the App, you agree to
                    the terms outlined in the Privacy Policy.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>4. User Accounts</h3>
                  <p>
                    a. You may need to create an account to use certain features
                    of the App. You are responsible for maintaining the
                    confidentiality of your account information and for all
                    activities that occur under your account.
                  </p>

                  <p>
                    b. You agree to provide accurate, current, and complete
                    information during the registration process.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>5. Content and Use</h3>
                  <p>
                    a. You are solely responsible for any content you submit,
                    post, or display on or via the App.
                  </p>
                  <p>
                    b. You agree not to use the App for any illegal or
                    unauthorized purpose.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>6. Intellectual Property</h3>
                  <p>
                    You may not reproduce, distribute, modify, create derivative
                    works of, publicly display, publicly perform, republish,
                    download, store, or transmit any of the material on our App.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>7. Termination</h3>
                  <p>
                    We reserve the right to terminate or suspend your account
                    and access to the App at our sole discretion, without
                    notice, for any reason, including without limitation, a
                    breach of these Terms.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>8. Limitation of Liability</h3>
                  <p>
                    To the fullest extent permitted by applicable law, [Your
                    Company Name] shall not be liable for any indirect,
                    incidental, special, consequential, or punitive damages, or
                    any loss of profits or revenues, whether incurred directly
                    or indirectly, or any loss of data, use, goodwill, or other
                    intangible losses.
                  </p>
                </div>

                <div className="terms-content-div">
                  <h3>9. Changes to Terms</h3>
                  <p>
                    ConvoBird reserves the right to update or modify these Terms
                    of Use at any time. Your continued use of the App after any
                    such changes indicates your acceptance of the new Terms.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Terms;
