import React, { forwardRef } from "react";
import "./FourSteps.css";
import { Col, Container, Image, Row } from "react-bootstrap";

import ReCaptcha from "../../assets/images/re-captcha.png";

const FourSteps = forwardRef((props, ref) => {
  return (
    <section className="FourSteps-sec" ref={ref} id="footerStep_form">
      <Container>
        <Row>
          <Col xl={6} lg={12}>
            <div className="FourSteps-content">
              <h2>4 Step Success</h2>

              <ul>
                <li>Book in your free 20-minute consultation.</li>
                <li>Discuss your specific needs.</li>
                <li>
                  We’ll match you with the ideal ConvoBird VA within 24 hours.
                </li>
                <li>
                  Now you can speak with your VA before making a decision.
                </li>
              </ul>

              <p>
                You can relax—there's no pressure or requirement to commit. Our
                complimentary consultation is an opportunity for us to learn
                about you and your business while giving you a clear idea of
                what partnering with a Virtalent Virtual Assistant entails.
              </p>
            </div>
          </Col>

          <Col xl={6} lg={12}>
            <div className="FourSteps-form-div">
              <div className="FourSteps-form-content">
                <h3>Let’s Get Started</h3>

                <div className="FourSteps-para">
                  <p>
                    Fill out the form below and mention which skills you need.
                  </p>

                  <p>We will be in touch to discuss your ideal candidate.</p>
                </div>
              </div>

              <div className="FourStep-form-content">
                <form>
                  <input type="text" placeholder="Full Name*" />
                  <input type="text" placeholder="Business Email*" />
                  <input type="text" placeholder="Phone*" />
                  <input type="text" placeholder="Company Name*" />
                  <input type="text" placeholder="Company URL*" />

                  <textarea placeholder="Enter required skills (e.g. Chat Answering, Voice Calls, Data Entry, Customer Service, Tech Support, Email Support, etc.)"></textarea>

                  <div className="FourSteps-submit-btn">
                    <button>Submit</button>
                  </div>
                </form>

                <div className="re-captcha-div">
                  <Image src={ReCaptcha} alt="re-captcha-image" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
});

export default FourSteps;
