import React, { forwardRef } from "react";
import "./AssistantSec.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AssistantSec = forwardRef((props, ref) => {
  return (
    <section className="assistant-sec" ref={ref} id="assistantSec">
      <Container>
        <Row>
          <Col lg={6} className="assistant-col-6">
            <div className="assistant-content">
              <h3>
                E-Commerce <br /> Virtual <span>Assistant</span>
              </h3>
              <p>
                Save time, increase sales and inspire customer loyalty with
                dedicated e-commerce Virtual Assistants equipped to build,
                manage, and maintain your store.
              </p>

              <div className="assistant-btn">
                <Link
                  to={"/ecommerce-virtual-assistance"}
                  className="orange-btn"
                >
                  Explore
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="assistant-content">
              <h3>
                Web Development <br /> Virtual <span>Assistant</span>
              </h3>
              <p>
                Convert visitors into customers, create your own apps, and keep
                your website updated and secure with a Web Development Virtual
                Assistant dedicated to your business.
              </p>

              <div className="assistant-btn">
                <Link to={"#"} className="orange-btn">
                  Explore
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
});

export default AssistantSec;
