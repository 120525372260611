import React, { forwardRef } from "react";
import "./PricingComp.css";
import { Col, Container, Row } from "react-bootstrap";

import TickYellow from "../../assets/images/tick-yellow.png";
import { Link } from "react-router-dom";

import FreeConsultModal from "../FreeConsultModal";

const PricingComp = forwardRef((prop, ref) => (
  <section className="pricing-sec" ref={ref}>
    <Container>
      <Row>
        <Col lg={3}>
          <div className="price-box price-box1">
            <div className="price-top">
              <div className="price-top-headings">
                <h3>STARTER</h3>
                <p>Small Office / Home Office</p>
              </div>

              <div className="price-rate">
                <sup>$</sup>
                <h4>20</h4>
              </div>

              <div className="price-rate-para">
                <p>
                  Per agent billed annually or <span>$24</span> month-to-month
                </p>
              </div>
            </div>
            <div className="price-bottom">
              <div className="price-bottom-list">
                <ul>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> 60-day chat
                    history
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Basic widget
                    customization
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Availability
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Data security
                  </li>
                </ul>
              </div>

              <div className="price-botton-plan">
                <Link to={"#"}>Get Plan</Link>
              </div>
            </div>
          </div>
        </Col>

        <Col lg={3}>
          <div className="price-box price-box2">
            <div className="price-top">
              <div className="price-top-headings">
                <h3>TEAM</h3>
                <p>Full time support team</p>
              </div>

              <div className="price-rate">
                <sup>$</sup>
                <h4>41</h4>
                <sub>/mo</sub>
              </div>

              <div className="price-rate-para">
                <p>
                  Per agent billed annually or <span>$49</span> month-to-month
                </p>
              </div>
            </div>
            <div className="price-bottom">
              <div className="price-bottom-list">
                <ul>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Unlimited chat
                    history
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Full widget
                    customization
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Availability
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Data security
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Basic reporting
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Agent groups
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Multiple brandings
                  </li>
                </ul>
              </div>

              <div className="price-botton-plan">
                <Link to={"#"}>Get Plan</Link>
              </div>
            </div>
          </div>
        </Col>

        <Col lg={3}>
          <div className="price-box price-box3">
            <div className="price-top">
              <div className="price-top-headings">
                <h3>BUSINESS</h3>
                <p>Customer Service Department</p>
              </div>

              <div className="price-rate">
                <sup>$</sup>
                <h4>41</h4>
                <sub>/mo</sub>
              </div>

              <div className="price-rate-para">
                <p>
                  Per agent billed annually or <span>$69</span> month-to-month
                </p>
              </div>
            </div>
            <div className="price-bottom">
              <div className="price-bottom-list">
                <ul>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Unlimited chat
                    history
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Full widget
                    customization
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Availability
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Data security
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Advanced reporting
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Agent groups
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Multiple brandings
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Staffing
                    prediction
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Work scheduler
                  </li>
                </ul>
              </div>

              <div className="price-botton-plan">
                <Link to={"#"}>Get Plan</Link>
              </div>
            </div>
          </div>
        </Col>

        <Col lg={3}>
          <div className="price-box price-box4">
            <div className="price-top">
              <div className="price-top-headings">
                <h3>ENTERPRISE</h3>
                <p>Global company, global reach</p>
              </div>

              <div className="price-request">
                <div className="price-request-one">
                  <FreeConsultModal startTrial="Request a Call" />
                  {/* <Link to={"/"}>Request a Call</Link> */}
                </div>

                <div className="price-request-two">
                  <Link to={"https://onechat.ledgecrm.com/"} target="_blank">
                    Learn More
                  </Link>
                </div>
              </div>

              <div className="price-rate-para">
                <p>Individual contract with annual billing</p>
              </div>
            </div>
            <div className="price-bottom">
              <div className="price-bottom-list">
                <ul>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Unlimited chat
                    history
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Full widget
                    customization
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Availability
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Data security
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Advanced reporting
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Agent groups
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Multiple brandings
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Staffing
                    prediction
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Work scheduler
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Dedicated acc.
                    manager
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Product training
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Software engineer
                    support
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Security
                    assistance
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> HIPAA Compliance
                  </li>
                  <li>
                    <img src={TickYellow} alt="TickYellow" /> Single Sign-On
                    (SSO)
                  </li>
                </ul>
              </div>

              <div className="price-botton-plan">
                {/* <Link to={"#"}>Get Plan</Link> */}
                <FreeConsultModal startTrial="Get Plan" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
));

export default PricingComp;
