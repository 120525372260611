import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./InnerBanner.css";

import VABannerImg from "../../assets/images/va-banner-img.png";
import FreeConsultModal from "../FreeConsultModal";

const InnerBanner = ({ scrolltoOverviewSec }) => {
  return (
    <section className="va-assistance-sec">
      <Container>
        <Row className="va-assistance-row">
          <Col lg={6}>
            <div className="va-assistance-content">
              <div className="va-small-heading">
                <div className="inner-dot"></div>
                <p className="inner-dot-para">Virtual assistance</p>
              </div>

              <h1>
                Get Things Efficiently Done <span>Virtually</span>
              </h1>

              <p>
                ConvoBird providing a platform where it refers you the best
                professional in the market for assistance.
              </p>

              <div className="va-banner-btn">
                <FreeConsultModal />
                <Link
                  to={"#"}
                  onClick={scrolltoOverviewSec}
                  className="orange-outline-btn"
                >
                  Services Overview
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="va-assistance-img">
              <img src={VABannerImg} alt="VABannerImg" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default InnerBanner;
