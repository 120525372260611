import React, { useState } from "react";
import "./SelectHours.css";
import { Col, Container, Row } from "react-bootstrap";

import HoursTen from "../../assets/images/hours-ten.png";
import HoursTenHover from "../../assets/images/hour-10-hover.png";

import HoursTwenty from "../../assets/images/hours-twenty.png";
import HoursTwentyHover from "../../assets/images/hour-20-hover.png";

import HoursThirty from "../../assets/images/hours-thirty.png";
import HoursThirtyHover from "../../assets/images/hour-30-hover.png";

import HoursFourty from "../../assets/images/hours-fourty.png";
import HoursFourtyHover from "../../assets/images/hour-40-hover.png";

import HoursFourtyEight from "../../assets/images/hours-fourtyeight.png";
import HoursFourtyEightHover from "../../assets/images/hour-48-hover.png";

const SelectHours = () => {
  const [isHover, setIsHover] = useState(false);
  const hoursData = [
    {
      id: 1,
      image: HoursTen,
      hoverImage: HoursTenHover,
      category: "hrs/week",
      para: "Plans starting from",
      price: "$600/mo",
    },

    {
      id: 2,
      image: HoursTwenty,
      hoverImage: HoursTwentyHover,
      category: "hrs/week",
      para: "Plans starting from",
      price: "$800/mo",
    },

    {
      id: 3,
      image: HoursThirty,
      hoverImage: HoursThirtyHover,
      category: "hrs/week",
      para: "Plans starting from",
      price: "$1,100/mo",
    },

    {
      id: 4,
      image: HoursFourty,
      hoverImage: HoursFourtyHover,
      category: "hrs/week",
      para: "Plans starting from",
      price: "$1,300/mo",
    },

    {
      id: 5,
      image: HoursFourtyEight,
      hoverImage: HoursFourtyEightHover,
      category: "hrs/week",
      para: "Plans starting from",
      price: "$1,500/mo",
    },
  ];

  const hoverTriggerEnter = () => {
    setIsHover(true);
  };

  const hoverTriggerLeave = () => {
    setIsHover(false);
  };

  return (
    <section className="select-hours-sec">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="select-hours-content">
              <h2>
                Select Hours You Need a VA For<span>?</span>
              </h2>
            </div>

            <div className="select-hours-boxMain">
              {hoursData.map((item) => {
                return (
                  <div
                    className="select-hours-box"
                    key={item.id}
                    onMouseEnter={hoverTriggerEnter}
                    onMouseLeave={hoverTriggerLeave}
                  >
                    <img
                      src={isHover ? item.hoverImage : item.image}
                      alt="HoursTen"
                    />

                    <h5>{item.category}</h5>

                    <p>
                      {item.para} <span>{item.price}</span>
                    </p>
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SelectHours;
