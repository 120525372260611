import React from "react";
import "./CoveredPlatforms.css";
import { Col, Container, Row } from "react-bootstrap";

import BImg from "../../assets/images/B-img.png";
import WooImg from "../../assets/images/woocommerce-img.png";
import ShopifyImg from "../../assets/images/shopify-img.png";
import amazonImg from "../../assets/images/amazon-img.png";
import ebayImg from "../../assets/images/ebay-img.png";
import elementorImg from "../../assets/images/elementor-img.png";
import squareImg from "../../assets/images/square-img.png";

import { Link } from "react-router-dom";
import FreeConsultModal from "../FreeConsultModal";

const CoveredPlatforms = () => {
  return (
    <section className="covered-platform-sec">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="covered-platform-img">
              <Row>
                <Col
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                  className="platform-img-border"
                >
                  <div className="platform-img-div">
                    <img src={BImg} alt="BImg img" />
                  </div>
                </Col>

                <Col
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                  className="platform-img-border"
                >
                  <div className="platform-img-div">
                    <img src={WooImg} alt="WooImg img" />
                  </div>
                </Col>

                <Col
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                  className="platform-img-border platform-img-border-right"
                >
                  <div className="platform-img-div">
                    <img src={ShopifyImg} alt="ShopifyImg img" />
                  </div>
                </Col>

                <Col
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                  className="platform-img-border"
                >
                  <div className="platform-img-div">
                    <img src={amazonImg} alt="amazonImg img" />
                  </div>
                </Col>

                <Col
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                  className="platform-img-border"
                >
                  <div className="platform-img-div">
                    <img src={ebayImg} alt="ebayImg img" />
                  </div>
                </Col>

                <Col
                  lg={4}
                  md={4}
                  sm={6}
                  xs={6}
                  className="platform-img-border platform-img-border-right"
                >
                  <div className="platform-img-div">
                    <img src={elementorImg} alt="elementorImg img" />
                  </div>
                </Col>

                <Col lg={4} md={4} sm={6} xs={6}></Col>

                <Col lg={4} md={4} sm={12} className="platform-img-main-border">
                  <div className="platform-img-div">
                    <img src={squareImg} alt="squareImg img" />
                  </div>
                </Col>

                <Col lg={4} md={4} sm={6} xs={6}></Col>
              </Row>
            </div>
          </Col>
          <Col lg={6}>
            <div className="covered-platform-content">
              <h2>
                Covered
                <span>Platforms</span>
              </h2>

              {/* <div className="covered-platform-link">
                <FreeConsultModal />
              </div> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default CoveredPlatforms;
