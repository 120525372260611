import React from "react";
import "./ContactBanner.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const ContactBanner = () => {
  return (
    <section className="va-assistance-sec va-assistance-sec2 contact-banner-assistance">
      <Container>
        <Row className="va-assistance-row">
          <Col lg={6}>
            <div className="va-assistance-content contact-assisrtance-content">
              <div className="va-small-heading">
                <div className="inner-dot"></div>
                <p className="inner-dot-para">Contact</p>
              </div>

              <h1>Get in Touch</h1>

              <p>
                Have a question, feedback, or just want to say hello? We're here
                for you! Feel free to reach out to us through our chat feature.
                Our dedicated support team is ready to assist you with any
                inquiries.
              </p>

              <p>
                Feel free to drop us a message anytime and we'll make sure to
                provide you with the assistance you need.
              </p>

              <div className="va-banner-btn">
                <Link to={"/"} className="orange-btn">
                  Live Chat
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="contact-banner-box">
              <div className="contact-banner-text">
                <h3>Let’s Get Started</h3>
                <p>
                  Fill out the form below and we will be in touch to discuss
                  your requirement
                </p>
              </div>

              <div className="contact-banner-form">
                <form>
                  <input type="text" placeholder="Full Name*" />
                  <input type="text" placeholder="Business Email*" />
                  <input type="text" placeholder="Phone*" />
                  <input type="text" placeholder="Company Name*" />
                  <input
                    type="text"
                    placeholder="Services You Are Looking For?"
                  />

                  <div className="contact-banner-btn">
                    <button>Submit</button>
                  </div>
                </form>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactBanner;
