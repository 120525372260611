import React from "react";
import "./VASecurity.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import SecurityPeoples from "../../assets/images/security-peoples.png";

const VASecurity = () => {
  return (
    <section className="va-security-sec">
      <Container>
        <Row>
          <Col lg={6}>
            <div className="va-security-content">
              <div className="va-small-heading">
                <div className="inner-dot"></div>
                <p className="inner-dot-para">security</p>
              </div>

              <h2>
                Hire VA From <br /> The <span>World</span>
              </h2>

              <p>
                A ConvoBird VA has an average of 15 years’ experience, even
                before joining our team. They’re dedicated, talented
                professionals who choose to work remotely.
              </p>

              <p>
                How do we know? Because we carefully vet each one before they
                join. We make sure they know their stuff and that they have the
                skills to get the job done; otherwise they don’t make the cut.
              </p>

              <div className="security-btn">
                <Link to={"#"} className="orange-btn">
                  Hire Now
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="security-img">
              <Image src={SecurityPeoples} alt="security-peoples" />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default VASecurity;
