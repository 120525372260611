import React, { useRef } from "react";
import "./EcommerceVA.css";
import { Helmet } from "react-helmet";

import EcommerceBanner from "../../components/EcommerceBanner";
import CoveredPlatforms from "../../components/CoveredPlatforms";
import EcommerceBenefits from "../../components/EcommerceBenefits";
import FreeConsultation from "../../components/FreeConsultation";
import EcomServices from "../../components/EcomServices";
import EasiestHire from "../../components/EasiestHire";
import SelectHours from "../../components/SelectHours";
import Testimonials from "../../components/Testimonials";
import StepsComp from "../../components/StepsComp";

import testiImgFour from "../../assets/images/testiImg4.png";
import EcommerceBannerImg from "../../assets/images/ecommerce-banner-img.png";

import CustomerRelationship from "../../assets/images/customer-relationship.png";
import DataInventoryControl from "../../assets/images/data-inventory-control.png";
import IdeationExecution from "../../assets/images/ideation-execution.png";
import BenefitsSupport from "../../assets/images/benefits-support.png";
import BirdIcon from "../../assets/images/bird-icon.png";

const EcommerceVA = () => {
  const servicesRef = useRef(null);
  const stepFormRef = useRef(null);

  const scrollToServicesSec = () => {
    servicesRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToStepFormSec = () => {
    stepFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const BannerContent = {
    image: EcommerceBannerImg,
    orangeTitle: "Ecommerce",
    heading1: "E-commerce Virtual",
    heading2: "Assistance",
    para: "Maximize time, boost sales, and foster customer loyalty using dedicated e-commerce Virtual Assistants adept at constructing, overseeing, and upholding your store.",
  };

  const ecommerceBenefitsContent = {
    orangetitle: "Benefits",
    heading1: "Benefits of Getting An",
    heading2: "Ecommerce",
    heading3: "Virtual Assistant",
    ecomBenefitsContentBox: [
      {
        title: "Building Customer Relationships",
        description:
          "Continuous availability guarantees seamless customer assistance for queries, purchases, and order tracking. A customer service VA provides reliable phone, chat, and email support, offering product advice to enhance the overall experience.",
        imgSrc: CustomerRelationship,
      },
      {
        title: "Data & Inventory In Control",
        description:
          "Admin and finance VAs tackle daily store operations like databases, inventory, payroll, and billing, freeing up your time for strategic focus.",
        imgSrc: DataInventoryControl,
      },
      {
        title: "Taking Ideation To Execution",
        description:
          "An e-commerce VA builds your store from scratch on your preferred platform. Creative design VAs breathe life into products using unique illustrations and captivating descriptions, fostering customer engagement and brand growth.",
        imgSrc: IdeationExecution,
      },
      {
        title: "Support 24/7",
        description:
          "Our HR team provides 24/7 support for managing your VA's performance. Once your VA is assigned, you'll receive a daily report showcasing their completed key tasks.",
        imgSrc: BenefitsSupport,
      },
    ],
  };

  const ecomServicesContent = {
    orangeTitle: "Services",
    heading1: "What An Ecommerce Virtual",
    heading2: "Assistant",
    heading3: "Can Do",
    services: [
      {
        title: "Administrative",
        subtitle: "Support",
        icon: BirdIcon,
        listItems: [
          "Executive assistant",
          "Data entry",
          "Manage files and documents",
          "Update product lists and prices",
          "Manage stock levels and place orders",
          "Accounting",
          "Bookkeeping",
          "Tax preparation",
          "Payroll",
          "Billing",
        ],
      },
      {
        title: "IT/Web",
        subtitle: "Development",
        icon: BirdIcon,
        listItems: [
          "Shopify Developer",
          "WordPress Developer",
          "Amazon store setup",
          "Etsy store setup",
          "Managing BigCommerce and other platforms",
          "Walmart store setup",
          "IT support",
          "Identifying and updating broken links",
          "Back-end SEO",
          "Monitoring and improving page load speed",
        ],
      },
      {
        title: "Customer",
        subtitle: "Support",
        icon: BirdIcon,
        listItems: [
          "Placing and updating orders",
          "Order tracking",
          "Handling product returns and exchanges",
          "Processing orders and refunds",
          "Managing returns",
          "Product recommendations",
          "Upselling and cross-selling",
          "Handling customer complaints",
          "Responding to product reviews",
          "24/7 chat support",
        ],
      },
      {
        title: "Marketing &",
        subtitle: "Graphic Design",
        icon: BirdIcon,
        listItems: [
          "Writing and editing product descriptions",
          "Web Development",
          "Social media management",
          "Writing ad copy",
          "Creating images for ads and social posts",
          "Video editing",
          "Customer research",
          "Creating blog content",
          "Track social media performance",
          "Keyword research",
        ],
      },
    ],
  };

  const stepCompContent = {
    title: "Hire E-commerce VA",
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ConvoBird | Ecommerce Virtual Assistance</title>
      </Helmet>

      <EcommerceBanner
        content={BannerContent}
        scrollToServicesSec={scrollToServicesSec}
      />
      <CoveredPlatforms />

      <EcommerceBenefits content={ecommerceBenefitsContent} />

      <FreeConsultation scrollToStepFormSec={scrollToStepFormSec} />

      <EcomServices
        pageType="ecom"
        content={ecomServicesContent}
        ref={servicesRef}
      />

      <EasiestHire />

      <SelectHours />

      <Testimonials testiImgFour={testiImgFour} />
      <StepsComp content={stepCompContent} ref={stepFormRef} />
    </>
  );
};

export default EcommerceVA;
